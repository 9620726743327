import {Button, Card, Collapse} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {ChevronDown, ChevronUp, Pencil} from "react-bootstrap-icons";
import Moment from "react-moment";
import React, {useState} from "react";
import moment from "moment-timezone";

function WearableCard(props){
    const {organization, wearable} = props;

    const [collapsed, setCollapsed] = useState(true);

    return <div>
        <LinkContainer to={`/org/${organization.id}/wearable/${wearable.id}/overview`}>
        <Card className="wearable-list-card">
            <Card.Header>
                <div>
                    <div><b>{wearable?.config?.name}</b></div>
                    <div className="spacer" />
                    <div className="me-2 text-muted">Wearable </div>
                    <div onClick={e => {e.preventDefault()}}>
                        { organization.roles.includes('admin') ? <LinkContainer to={`/org/${organization.id}/wearable/${wearable.id}/config`}>
                            <Button variant="link" className="toolbar-hidden-button" size="sm"><Pencil /></Button>
                        </LinkContainer> : null }
                    </div>
                </div>
            </Card.Header>
            <Card.Body>

                <p><b>Last Seen:</b> <Moment fromNow>{wearable.lastSeen}</Moment></p>
                <p><b>Device ID:</b> {wearable.deviceId}</p>
                {
                    (wearable.instantaneous.json.id !== undefined) ?
                    <>
                        <p><b>Battery:</b> {wearable.instantaneous.json.battery} %</p>

                        <p><b>Gateway ID:</b> {wearable.instantaneous.json.gateway?.deviceId}</p>

                        {wearable.alert.json ?
                            <p><b>Alert:</b> {wearable.alert.getAlertState[1]} - <Moment unix format="MM/DD/YYYY - hh:mm:ss A" local>{ wearable.alert.timestamp / 1000}</Moment>
                        {' '}
                        {moment.tz.zone(moment.tz.guess()).abbr(wearable.alert.timestamp)}</p>
                        :
                        <p><b>Alert:</b> None</p>}

                        <Collapse in={!collapsed}>
                            <div>
                                <hr />
                                <p> Last update <Moment fromNow>{wearable.instantaneous.timestamp}</Moment>:</p>
                                <div className="ms-3">
                                    <p><b>Active Calories: </b> {wearable.instantaneous.json.actCals} Cal </p>
                                    <p><b>BMR Calories: </b> {wearable.instantaneous.json.bmrCals} Cal </p>
                                    <p><b>Total Calories: </b> {wearable.instantaneous.json.totalCals} Cal </p>
                                    <p><b>Case Temperature: </b> {wearable.instantaneous.json.caseTemp}  deg C </p>
                                    <p><b>Skin Temperature: </b> {wearable.instantaneous.json.skinTemp} deg C </p>
                                    <p><b>Solar Generation: </b> {wearable.instantaneous.json.solarGen} {"\xB5Wh"} </p>
                                    <p><b>Thermal Generation: </b> {wearable.instantaneous.json.thermalGen} {"\xB5Wh"} </p>
                                    <p><b>Distance: </b> {wearable.instantaneous.json.distance} m </p>
                                    <p><b>Total Steps: </b> {wearable.instantaneous.json.totalSteps} </p>
                                    <p><b>Running Steps: </b> {wearable.instantaneous.json.runSteps} </p>
                                    <p><b>Walking Steps: </b> {wearable.instantaneous.json.walkSteps} </p>
                                    <p><b>Sleep Duration: </b> {wearable.instantaneous.json.sleepDuration} minutes </p>
                                    {wearable.instantaneous.json.heartRate !== null
                                        ? <p><b>Heart Rate: </b> {wearable.instantaneous.json.heartRate} BPM </p>
                                        : null
                                    }
                                </div>
                            </div>
                        </Collapse>
                        <div className="text-center">
                            <Button variant="link" onClick={e => {setCollapsed(prev => !prev); e.stopPropagation(); }}>
                                {collapsed ? <ChevronDown /> : <ChevronUp />}
                            </Button>
                        </div>
                    </>
                    : null
                }
            </Card.Body>
        </Card>
        </LinkContainer>
    </div>;
}

export default WearableCard;