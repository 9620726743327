import {useBoxDimensions} from "../windowDimensions";
import FullscreenSpinner from "../FullscreenSpinner";
import React from "react";
import Moment from "react-moment";
import {Card, Col, Row} from "react-bootstrap";
import moment from "moment-timezone";

function WearableOverviewView(props){
    const {organization, wearable, wearableMetrics, context} = props;
    // console.log(wearable)
    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    const calendarStrings = {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek : '[Last] dddd',
        nextWeek : 'dddd',
        sameElse : 'L'
    };

    return <div className="mt-2">
        <Row>
            <Col><p className="display-3"><Moment calendar={calendarStrings}>{context.date}</Moment></p></Col>
        </Row>
        <Row>
            <Col><p className="display-6"><Moment format={"dddd"}>{context.date}</Moment></p></Col>
        </Row>


        <Row className="mb-3">
            <Col md={4}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">BMR Calories</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("delta_bmr_cals") / 1000).toFixed(1) } Cal</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Active Calories</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("delta_act_cals") / 1000).toFixed(1) } Cal</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row className="mb-3">
            <Col md={4}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Total power from body</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("thermal_gen_sum")/60).toFixed(2)  } {"\xB5Wh"}</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Total power from light </p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("solar_gen_sum")/60).toFixed(2) } {"\xB5Wh"}</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row className="mb-3">
            <Col md={4}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Total steps</p>
                        <p className="metric-value display-5">{ wearableMetrics.sumMetric("delta_run_steps") + wearableMetrics.sumMetric("delta_walk_steps")}</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Total distance </p>
                        <p className="metric-value display-5">{ wearableMetrics.sumMetric("delta_distance") } m </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row className="mb-3">
            <Col md={8}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Alert</p>
                        {wearable.alert.json ? 
                            <>
                                <p className="metric-value display-5">State: { wearable.alert.getAlertState[1]}</p>
                                <p className="metric-value display-5">Time: {' '}
                                    <Moment unix format="MM/DD/YYYY - hh:mm:ss A" local>
                                        { wearable.alert.timestamp / 1000}
                                    </Moment>
                                    {' '}
                                    {moment.tz.zone(moment.tz.guess()).abbr(wearable.alert.timestamp)}
                                </p>
                            </>
                        : 
                            <p className="metric-value display-5">None</p>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    </div>;
}

export default WearableOverviewView;